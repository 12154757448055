@import 'css/normalize.css';
.segment label, .check label, h3 {
  font-family: "obviously", sans-serif;
  font-weight: 700;
}

#loginTable .inputBlock label, .boldText, h2, h1, .btn {
  font-family: "obviously-wide", sans-serif;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

/* html element 62.5% font-size for REM use */
html {
  font-size: 62.5%;
  height: 100%;
}

.isHidden {
  display: none;
}

p {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 500;
}

.fieldGroup {
  *zoom: 1;
}
.fieldGroup:before, .fieldGroup:after {
  content: " ";
  display: table;
}
.fieldGroup:after {
  clear: both;
}

body {
  font-size: 14px;
  line-height: 1.6;
  font-weight: 300;
  font-family: "obviously", sans-serif;
  color: #FAF9FC;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  -webkit-touch-callout: none !important;
  background: #432277;
  border: solid 15px #0D0120;
  font-weight: 500;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

a {
  color: #0D0120;
  text-decoration: underline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: all 0.4s ease;
}

a:focus {
  outline: 0;
}

a:hover,
a:active {
  transition: all 0.4s ease;
  outline: 0;
  opacity: 0.5;
}

.fieldGroup {
  *zoom: 1;
}
.fieldGroup:before, .fieldGroup:after {
  content: " ";
  display: table;
}
.fieldGroup:after {
  clear: both;
}

.btn {
  border-radius: 100px;
  border: solid 2px rgba(250, 249, 252, 0.3);
  cursor: pointer;
  font-size: 15px;
  padding: 10px 30px 13px;
  display: inline-block;
  transition: all 0.4s ease;
  color: #FAF9FC;
  text-decoration: none;
  line-height: 1.5;
  position: relative;
  margin-right: 16px;
}
.btn img {
  width: 20px;
}
.btn:hover {
  opacity: 1;
  background: rgba(250, 249, 252, 0.1);
  transition: all 0.4s ease;
  border-color: rgba(250, 249, 252, 0.5);
}
.btn.ghostIt {
  background: transparent;
  color: #1b1b1b;
  border: solid 1px #1b1b1b;
}
.btn.ghost span {
  z-index: 2;
  position: relative;
}
.btn.ghost::after {
  content: "";
  background: #231e27;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  position: absolute;
  border-radius: 8px;
  z-index: 1;
}

h1, h2, h3, h4, p {
  margin-bottom: 10px;
  position: relative;
}

h1 {
  font-size: 38px;
  line-height: 35px;
}

h2 {
  font-size: 32px;
  line-height: 30px;
}

h3 {
  font-size: 20px;
  line-height: 18px;
}

h4 {
  font-size: 20px;
  line-height: 1.15;
}

p {
  font-size: 18px;
  line-height: 20px;
}
p.smallText {
  font-size: 15px;
}

.pullRight {
  float: right;
  margin-right: 15px;
  margin-top: 8px;
}

.error {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.card {
  background: #FAF9FC;
  box-shadow: 5px 10px 40px rgba(86, 8, 210, 0.13);
  border-radius: 16px;
  padding: 30px;
  display: inline-block;
  width: 100%;
  position: relative;
}

.absLink {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

ul {
  padding-left: 0;
}

#next {
  position: absolute;
  right: 20px;
}
#next p {
  margin-right: 10px;
}

#logoutBtn {
  position: absolute;
  top: 20px;
  right: 20px;
}

#helpButton {
  position: absolute;
  top: 20px;
  left: 20px;
}
#helpButton .btn {
  margin-right: 20px;
}

#loginTable .inputBlock {
  display: inline-block;
  margin-bottom: 11px;
  position: relative;
  text-align: left;
}
#loginTable .inputBlock.inputBlock--full {
  display: block;
  flex-basis: 100%;
}
#loginTable .inputBlock label {
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #8C7376;
  width: 100%;
}
#loginTable .inputBlock input, #loginTable .inputBlock select, #loginTable .inputBlock textarea {
  border: solid 1px #d2d2d2;
  width: 100%;
  padding: 0.5vw 0.35vw;
  font-size: 14px;
  outline: 0;
  background: white;
  border-radius: 0;
}
#loginTable .inputBlock input:focus, #loginTable .inputBlock select:focus, #loginTable .inputBlock textarea:focus {
  border: solid 1px #cfcfcc;
}
#loginTable .inputBlock select {
  -webkit-appearance: none;
  padding: 0.3vw 0.35vw;
  background: transparent;
}
#loginTable .inputBlock input[type=checkbox] {
  background: blue;
}

.modal.isError--pw .modalInner, .modal.isError--name .modalInner {
  border: 5px solid #5608D2;
}
.modal.isError--pw .modalInner::after, .modal.isError--name .modalInner::after {
  content: "That name is not in our records!";
  position: absolute;
  top: 0;
  transform: translateY(-125%);
  color: #fff;
  background: #C31F2F;
  padding: 2vw;
  width: calc(100% - 4vw);
  left: 0;
}

.modal.isError--pw .modalInner::after {
  content: "Incorrect Password!";
}

.modal .LoginForm.modalInner .modalTitle {
  width: 250px;
}

.modal .LoginForm.modalInner {
  text-align: center;
}
.modal .LoginForm.modalInner .btn {
  width: 100%;
  margin-top: 20px;
}

.modal {
  position: fixed;
  top: 75px;
  left: 10px;
  width: 350px;
  border: solid 2px #da6565;
}
.modal .btn {
  display: inline-block;
  padding: 3px 16px 5px;
  font-size: 14px;
  margin-top: 15px;
  margin-right: 5px;
  background: #da6565;
}
.modal .btn.ghostIt {
  background: transparent;
}
.modal .btn:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 35px;
  }

  p {
    font-size: 16px;
    line-height: 1.1;
  }

  h2 {
    font-size: 24px;
    line-height: 1.1;
  }

  h3 {
    font-size: 25px;
    line-height: 30px;
  }

  h4 {
    font-size: 20px;
    line-height: 25px;
  }
}
label {
  width: 100%;
}

input {
  border: 0;
  padding: 4px;
  font-size: 16px;
  width: 100%;
  padding: 9px 8px;
  outline: 0;
  border: solid 2px #EBEBE8;
  border-radius: 5px;
  box-shadow: 0 0px 0px transparent;
  transition: all 0.25s ease;
}
input:focus {
  box-shadow: 0 5px 9px rgba(86, 8, 210, 0.25);
  border-color: #5608D2;
  transition: all 0.25s ease;
}
input[disabled] {
  cursor: default;
  background: #fff;
  border-color: #f1f2eb;
  color: #9c9c9c;
}

textarea {
  width: 100% !important;
  background: #FAFCFC;
  border: 0;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  outline: 0;
  border: solid 2px #EBEBE8;
  border-radius: 5px;
  box-shadow: 0 0px 0px transparent;
  transition: all 0.25s ease;
}
textarea:focus {
  box-shadow: 0 5px 9px rgba(86, 8, 210, 0.25);
  border-color: #5608D2;
  background: white;
  transition: all 0.25s ease;
}

select {
  -webkit-appearance: none;
  background: #FAF9FC;
  border-radius: 5px;
  font-size: 16px;
  padding: 9px 8px;
  border: none;
  width: 100%;
  outline: 0;
  border: solid 2px #EBEBE8;
  border-radius: 5px;
  box-shadow: 0 0px 0px transparent;
  transition: all 0.25s ease;
  cursor: pointer;
}
select:focus {
  box-shadow: 0 5px 9px rgba(86, 8, 210, 0.25);
  border-color: #5608D2;
  transition: all 0.25s ease;
}

.selectBlock {
  position: relative;
  cursor: pointer;
}
.selectBlock::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid #b0b0a4;
}
.selectBlock:focus-within::after {
  border-top: 5px solid #5608D2;
}

input[type=color] {
  padding: 0 3px;
  margin: 0px;
  height: 35px;
}

input[type=checkbox] {
  -webkit-appearance: none;
  width: 100%;
  padding: 16.5px;
  margin: 0;
}
input[type=checkbox]:checked {
  position: relative;
}
input[type=checkbox]:checked::after {
  content: "YES";
  background: #5608D2;
  position: absolute;
  text-align: center;
  font-size: 12px;
  font-weight: 900;
  line-height: 23px;
  color: white;
  top: 10%;
  left: 3%;
  right: 3%;
  bottom: 10%;
  border-radius: 3px;
}

.check {
  flex: 1 1 0;
  position: relative;
  margin: 0 5px;
  height: 35px;
}
.check label {
  font-size: 12px;
  color: #0D0120;
}
.check:first-child {
  margin-left: 0;
}
.check:last-child {
  margin-right: 0;
}
.check input[type=checkbox] {
  position: relative;
  margin: 0;
}
.check input[type=checkbox]::after {
  content: "";
  background: #0D0120;
}
.check input[type=checkbox] + label {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.check input[type=checkbox]:checked {
  border-color: #0D0120;
}
.check input[type=checkbox]:checked + label {
  color: #FAF9FC;
}

.segment {
  flex: 1 1 0;
  position: relative;
}
.segment label {
  font-size: 12px;
  color: #0D0120;
}
.segment input[type=radio] {
  -webkit-appearance: none;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 14px;
  border-color: #0D0120;
  cursor: pointer;
  border-right: 0px;
  padding-bottom: 13px;
}
.segment input[type=radio] + label {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  cursor: pointer;
}
.segment input[type=radio]:focus, .segment input[type=radio]:active {
  box-shadow: none;
  transition: all 0.25s ease;
}
.segment input[type=radio]:checked {
  background: #0D0120;
  cursor: default;
}
.segment input[type=radio]:checked + label {
  cursor: default;
  color: #FAF9FC;
}
.segment:first-child input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.segment:last-child input {
  border-right: 1px solid #0D0120;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

input[type=date], input[type=datetime-local], input[type=time] {
  padding: 4px 6px 5px;
}

input[type=date], input[type=datetime-local] {
  position: relative;
  /* adjust clear button */
}
input[type=date]::-webkit-inner-spin-button, input[type=datetime-local]::-webkit-inner-spin-button {
  opacity: 0;
}
input[type=date]::-webkit-datetime-edit-ampm-field, input[type=date]::-webkit-datetime-edit-hour-field, input[type=date]::-webkit-datetime-edit-minute-field, input[type=datetime-local]::-webkit-datetime-edit-ampm-field, input[type=datetime-local]::-webkit-datetime-edit-hour-field, input[type=datetime-local]::-webkit-datetime-edit-minute-field {
  z-index: 1;
  position: relative;
}
input[type=date]:after, input[type=datetime-local]:after {
  content: "";
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid #b0b0a4;
}
input[type=date]::-webkit-calendar-picker-indicator, input[type=datetime-local]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}
input[type=date]::-webkit-clear-button, input[type=datetime-local]::-webkit-clear-button {
  z-index: 1;
}

input[type=range] {
  -webkit-appearance: none;
  border: 1px solid white;
  width: 100%;
  cursor: grab;
}
input[type=range]:focus {
  border: 1px solid white;
  box-shadow: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  background: #EBEBE8;
  border: none;
  border-radius: 3px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #0D0120;
  margin-top: -9px;
  transition: all 0.25s ease;
}

input[type=range]:focus {
  outline: none;
}
input[type=range]:focus::-webkit-slider-thumb {
  background: #5608D2;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #EBEBE8;
}
input[type=range]:focus::-ms-fill-lower {
  background: #5608D2;
}
input[type=range]:focus::-ms-fill-upper {
  background: #EBEBE8;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  background: #EBEBE8;
  border: none;
  border-radius: 3px;
}

input[type=range]::-moz-range-thumb {
  border: none;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #0D0120;
}

/*hide the outline behind the border*/
input[type=range]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  background: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;
}

input[type=range]::-ms-fill-lower {
  background: #0D0120;
  border-radius: 10px;
}

input[type=range]::-ms-fill-upper {
  background: #EBEBE8;
  border-radius: 10px;
}

input[type=range]::-ms-thumb {
  border: none;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #0D0120;
}

.frame {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.well {
  padding-top: 2.5%;
  padding-bottom: 2.5%;
}

.well--xl {
  padding-top: 10%;
  padding-bottom: 10%;
}

.well--l {
  padding-top: 5%;
  padding-bottom: 5%;
}

.well--s {
  padding-top: 1.5%;
  padding-bottom: 1.5%;
}

.pt--xl {
  padding-top: 10%;
}

.pt--l {
  padding-top: 5%;
}

.pt--s {
  padding-top: 1.5%;
}

.pt--none {
  padding-top: 0;
}

.pb--xl {
  padding-bottom: 10%;
}

.pb--l {
  padding-bottom: 5%;
}

.pb--s {
  padding-bottom: 1.5%;
}

.pb--none {
  padding-bottom: 0;
}

.pad-1v {
  padding: 1vw;
}

.pad-2v {
  padding: 2vw;
}

.pad-3v {
  padding: 3vw;
}

.pad-4v {
  padding: 4vw;
}

.pad-5v {
  padding: 5vw;
}

.pad-6v {
  padding: 6vw;
}

.pad-7v {
  padding: 7vw;
}

.pad-8v {
  padding: 8vw;
}

.pad-1 {
  padding: 3%;
}

.pad-2 {
  padding: 6%;
}

.pad-3 {
  padding: 9%;
}

.pad-4 {
  padding: 12%;
}

.pad-5 {
  padding: 15%;
}

.pad-6 {
  padding: 18%;
}

.pad-7 {
  padding: 21%;
}

.pad-8 {
  padding: 24%;
}

.pad-10px {
  padding: 10px;
}

.pad-15px {
  padding: 15px;
}

.pad-25px {
  padding: 25px;
}

.pad-50px {
  padding: 50px;
}

.pad-75px {
  padding: 75px;
}

.pad-100px {
  padding: 100px;
}

.vPad-0 {
  padding-bottom: 0;
  padding-top: 0;
}

.hPad-0 {
  padding-left: 0;
  padding-right: 0;
}

.topPad-0 {
  padding-top: 0;
}

.bottomPad-0 {
  padding-bottom: 0;
}

.leftPad-0 {
  padding-left: 0;
}

.rightPad-0 {
  padding-right: 0;
}

.gap-1v {
  margin: 1vw;
}

.gap-2v {
  margin: 2vw;
}

.gap-3v {
  margin: 3vw;
}

.gap-4v {
  margin: 4vw;
}

.gap-5v {
  margin: 5vw;
}

.gap-6v {
  margin: 6vw;
}

.gap-7v {
  margin: 7vw;
}

.gap-8v {
  margin: 8vw;
}

.gap-1 {
  margin: 3%;
}

.gap-2 {
  margin: 6%;
}

.gap-3 {
  margin: 9%;
}

.gap-4 {
  margin: 12%;
}

.gap-5 {
  margin: 15%;
}

.gap-6 {
  margin: 18%;
}

.gap-7 {
  margin: 21%;
}

.gap-8 {
  margin: 24%;
}

.gap-10px {
  margin: 10px;
}

.gap-15px {
  margin: 15px;
}

.gap-25px {
  margin: 25px;
}

.gap-50px {
  margin: 50px;
}

.gap-75px {
  margin: 75px;
}

.gap-100px {
  margin: 100px;
}

.vGap-0 {
  margin-bottom: 0;
  margin-top: 0;
}

.hGap-0 {
  margin-left: 0;
  margin-right: 0;
}

.topGap-0 {
  margin-top: 0;
}

.bottomGap-0 {
  margin-bottom: 0;
}

.leftGap-0 {
  margin-left: 0;
}

.rightGap-0 {
  margin-right: 0;
}

.container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}
.container .nav {
  height: 70px;
  box-shadow: 0 5px 9px rgba(13, 1, 32, 0.15);
}
.container .split {
  position: relative;
}
.container .col-1 {
  flex-basis: 8.333%;
}
.container .col-2 {
  flex-basis: 16.667%;
}
.container .col-3 {
  flex-basis: 25%;
}
.container .col-4 {
  flex-basis: 33.333%;
}
.container .col-5 {
  flex-basis: 41.667%;
}
.container .col-6 {
  flex-basis: 50%;
}
.container .col-7 {
  flex-basis: 58.333%;
}
.container .col-8 {
  flex-basis: 66.667%;
}
.container .col-9 {
  flex-basis: 75%;
}
.container .col-10 {
  flex-basis: 83.333%;
}
.container .col-11 {
  flex-basis: 91.667%;
}
.container .col-12 {
  flex-basis: 100%;
}
.container.gutter-1 .col-1, .container.gutter-1 .col-2, .container.gutter-1 .col-3, .container.gutter-1 .col-4, .container.gutter-1 .col-5, .container.gutter-1 .col-6, .container.gutter-1 .col-7, .container.gutter-1 .col-8, .container.gutter-1 .col-9, .container.gutter-1 .col-10, .container.gutter-1 .col-11 {
  margin-right: 1%;
}
.container.gutter-1 .col-1:last-child, .container.gutter-1 .col-2:last-child, .container.gutter-1 .col-3:last-child, .container.gutter-1 .col-4:last-child, .container.gutter-1 .col-5:last-child, .container.gutter-1 .col-6:last-child, .container.gutter-1 .col-7:last-child, .container.gutter-1 .col-8:last-child, .container.gutter-1 .col-9:last-child, .container.gutter-1 .col-10:last-child, .container.gutter-1 .col-11:last-child {
  margin-right: 0;
}
.container.gutter-2 .col-1, .container.gutter-2 .col-2, .container.gutter-2 .col-3, .container.gutter-2 .col-4, .container.gutter-2 .col-5, .container.gutter-2 .col-6, .container.gutter-2 .col-7, .container.gutter-2 .col-8, .container.gutter-2 .col-9, .container.gutter-2 .col-10, .container.gutter-2 .col-11 {
  margin-right: 2%;
}
.container.gutter-2 .col-1:last-child, .container.gutter-2 .col-2:last-child, .container.gutter-2 .col-3:last-child, .container.gutter-2 .col-4:last-child, .container.gutter-2 .col-5:last-child, .container.gutter-2 .col-6:last-child, .container.gutter-2 .col-7:last-child, .container.gutter-2 .col-8:last-child, .container.gutter-2 .col-9:last-child, .container.gutter-2 .col-10:last-child, .container.gutter-2 .col-11:last-child {
  margin-right: 0;
}
.container.gutter-3 .col-1, .container.gutter-3 .col-2, .container.gutter-3 .col-3, .container.gutter-3 .col-4, .container.gutter-3 .col-5, .container.gutter-3 .col-6, .container.gutter-3 .col-7, .container.gutter-3 .col-8, .container.gutter-3 .col-9, .container.gutter-3 .col-10, .container.gutter-3 .col-11 {
  margin-right: 3%;
}
.container.gutter-3 .col-1:last-child, .container.gutter-3 .col-2:last-child, .container.gutter-3 .col-3:last-child, .container.gutter-3 .col-4:last-child, .container.gutter-3 .col-5:last-child, .container.gutter-3 .col-6:last-child, .container.gutter-3 .col-7:last-child, .container.gutter-3 .col-8:last-child, .container.gutter-3 .col-9:last-child, .container.gutter-3 .col-10:last-child, .container.gutter-3 .col-11:last-child {
  margin-right: 0;
}
.container.gutter-4 .col-1, .container.gutter-4 .col-2, .container.gutter-4 .col-3, .container.gutter-4 .col-4, .container.gutter-4 .col-5, .container.gutter-4 .col-6, .container.gutter-4 .col-7, .container.gutter-4 .col-8, .container.gutter-4 .col-9, .container.gutter-4 .col-10, .container.gutter-4 .col-11 {
  margin-right: 4%;
}
.container.gutter-4 .col-1:last-child, .container.gutter-4 .col-2:last-child, .container.gutter-4 .col-3:last-child, .container.gutter-4 .col-4:last-child, .container.gutter-4 .col-5:last-child, .container.gutter-4 .col-6:last-child, .container.gutter-4 .col-7:last-child, .container.gutter-4 .col-8:last-child, .container.gutter-4 .col-9:last-child, .container.gutter-4 .col-10:last-child, .container.gutter-4 .col-11:last-child {
  margin-right: 0;
}
.container.gutter-5 .col-1, .container.gutter-5 .col-2, .container.gutter-5 .col-3, .container.gutter-5 .col-4, .container.gutter-5 .col-5, .container.gutter-5 .col-6, .container.gutter-5 .col-7, .container.gutter-5 .col-8, .container.gutter-5 .col-9, .container.gutter-5 .col-10, .container.gutter-5 .col-11 {
  margin-right: 5%;
}
.container.gutter-5 .col-1:last-child, .container.gutter-5 .col-2:last-child, .container.gutter-5 .col-3:last-child, .container.gutter-5 .col-4:last-child, .container.gutter-5 .col-5:last-child, .container.gutter-5 .col-6:last-child, .container.gutter-5 .col-7:last-child, .container.gutter-5 .col-8:last-child, .container.gutter-5 .col-9:last-child, .container.gutter-5 .col-10:last-child, .container.gutter-5 .col-11:last-child {
  margin-right: 0;
}
.container.gutter-6 .col-1, .container.gutter-6 .col-2, .container.gutter-6 .col-3, .container.gutter-6 .col-4, .container.gutter-6 .col-5, .container.gutter-6 .col-6, .container.gutter-6 .col-7, .container.gutter-6 .col-8, .container.gutter-6 .col-9, .container.gutter-6 .col-10, .container.gutter-6 .col-11 {
  margin-right: 6%;
}
.container.gutter-6 .col-1:last-child, .container.gutter-6 .col-2:last-child, .container.gutter-6 .col-3:last-child, .container.gutter-6 .col-4:last-child, .container.gutter-6 .col-5:last-child, .container.gutter-6 .col-6:last-child, .container.gutter-6 .col-7:last-child, .container.gutter-6 .col-8:last-child, .container.gutter-6 .col-9:last-child, .container.gutter-6 .col-10:last-child, .container.gutter-6 .col-11:last-child {
  margin-right: 0;
}
.container.gutter-7 .col-1, .container.gutter-7 .col-2, .container.gutter-7 .col-3, .container.gutter-7 .col-4, .container.gutter-7 .col-5, .container.gutter-7 .col-6, .container.gutter-7 .col-7, .container.gutter-7 .col-8, .container.gutter-7 .col-9, .container.gutter-7 .col-10, .container.gutter-7 .col-11 {
  margin-right: 7%;
}
.container.gutter-7 .col-1:last-child, .container.gutter-7 .col-2:last-child, .container.gutter-7 .col-3:last-child, .container.gutter-7 .col-4:last-child, .container.gutter-7 .col-5:last-child, .container.gutter-7 .col-6:last-child, .container.gutter-7 .col-7:last-child, .container.gutter-7 .col-8:last-child, .container.gutter-7 .col-9:last-child, .container.gutter-7 .col-10:last-child, .container.gutter-7 .col-11:last-child {
  margin-right: 0;
}
.container.gutter-8 .col-1, .container.gutter-8 .col-2, .container.gutter-8 .col-3, .container.gutter-8 .col-4, .container.gutter-8 .col-5, .container.gutter-8 .col-6, .container.gutter-8 .col-7, .container.gutter-8 .col-8, .container.gutter-8 .col-9, .container.gutter-8 .col-10, .container.gutter-8 .col-11 {
  margin-right: 8%;
}
.container.gutter-8 .col-1:last-child, .container.gutter-8 .col-2:last-child, .container.gutter-8 .col-3:last-child, .container.gutter-8 .col-4:last-child, .container.gutter-8 .col-5:last-child, .container.gutter-8 .col-6:last-child, .container.gutter-8 .col-7:last-child, .container.gutter-8 .col-8:last-child, .container.gutter-8 .col-9:last-child, .container.gutter-8 .col-10:last-child, .container.gutter-8 .col-11:last-child {
  margin-right: 0;
}
.container.from-left {
  flex-direction: row;
}
.container.from-right {
  flex-direction: row-reverse;
}
.container.from-top {
  flex-direction: column;
}
.container.from-bottom {
  flex-direction: column-reverse;
}
.container.from-top .col-1, .container.from-bottom .col-1 {
  flex-basis: 8.333%;
}
.container.from-top .col-2, .container.from-bottom .col-2 {
  flex-basis: 16.667%;
}
.container.from-top .col-3, .container.from-bottom .col-3 {
  flex-basis: 25%;
}
.container.from-top .col-4, .container.from-bottom .col-4 {
  flex-basis: 33.333%;
}
.container.from-top .col-5, .container.from-bottom .col-5 {
  flex-basis: 41.667%;
}
.container.from-top .col-6, .container.from-bottom .col-6 {
  flex-basis: 50%;
}
.container.from-top .col-7, .container.from-bottom .col-7 {
  flex-basis: 58.333%;
}
.container.from-top .col-8, .container.from-bottom .col-8 {
  flex-basis: 66.667%;
}
.container.from-top .col-9, .container.from-bottom .col-9 {
  flex-basis: 75%;
}
.container.from-top .col-10, .container.from-bottom .col-10 {
  flex-basis: 83.333%;
}
.container.from-top .col-11, .container.from-bottom .col-11 {
  flex-basis: 91.667%;
}
.container.from-top .col-12, .container.from-bottom .col-12 {
  flex-basis: 100%;
}
.container.from-top.gutter-1 .col-1, .container.from-top.gutter-1 .col-2, .container.from-top.gutter-1 .col-3, .container.from-top.gutter-1 .col-4, .container.from-top.gutter-1 .col-5, .container.from-top.gutter-1 .col-6, .container.from-top.gutter-1 .col-7, .container.from-top.gutter-1 .col-8, .container.from-top.gutter-1 .col-9, .container.from-top.gutter-1 .col-10, .container.from-top.gutter-1 .col-11, .container.from-bottom.gutter-1 .col-1, .container.from-bottom.gutter-1 .col-2, .container.from-bottom.gutter-1 .col-3, .container.from-bottom.gutter-1 .col-4, .container.from-bottom.gutter-1 .col-5, .container.from-bottom.gutter-1 .col-6, .container.from-bottom.gutter-1 .col-7, .container.from-bottom.gutter-1 .col-8, .container.from-bottom.gutter-1 .col-9, .container.from-bottom.gutter-1 .col-10, .container.from-bottom.gutter-1 .col-11 {
  margin-bottom: 1%;
}
.container.from-top.gutter-1 .col-1:last-child, .container.from-top.gutter-1 .col-2:last-child, .container.from-top.gutter-1 .col-3:last-child, .container.from-top.gutter-1 .col-4:last-child, .container.from-top.gutter-1 .col-5:last-child, .container.from-top.gutter-1 .col-6:last-child, .container.from-top.gutter-1 .col-7:last-child, .container.from-top.gutter-1 .col-8:last-child, .container.from-top.gutter-1 .col-9:last-child, .container.from-top.gutter-1 .col-10:last-child, .container.from-top.gutter-1 .col-11:last-child, .container.from-bottom.gutter-1 .col-1:last-child, .container.from-bottom.gutter-1 .col-2:last-child, .container.from-bottom.gutter-1 .col-3:last-child, .container.from-bottom.gutter-1 .col-4:last-child, .container.from-bottom.gutter-1 .col-5:last-child, .container.from-bottom.gutter-1 .col-6:last-child, .container.from-bottom.gutter-1 .col-7:last-child, .container.from-bottom.gutter-1 .col-8:last-child, .container.from-bottom.gutter-1 .col-9:last-child, .container.from-bottom.gutter-1 .col-10:last-child, .container.from-bottom.gutter-1 .col-11:last-child {
  margin-bottom: 0;
}
.container.from-top.gutter-2 .col-1, .container.from-top.gutter-2 .col-2, .container.from-top.gutter-2 .col-3, .container.from-top.gutter-2 .col-4, .container.from-top.gutter-2 .col-5, .container.from-top.gutter-2 .col-6, .container.from-top.gutter-2 .col-7, .container.from-top.gutter-2 .col-8, .container.from-top.gutter-2 .col-9, .container.from-top.gutter-2 .col-10, .container.from-top.gutter-2 .col-11, .container.from-bottom.gutter-2 .col-1, .container.from-bottom.gutter-2 .col-2, .container.from-bottom.gutter-2 .col-3, .container.from-bottom.gutter-2 .col-4, .container.from-bottom.gutter-2 .col-5, .container.from-bottom.gutter-2 .col-6, .container.from-bottom.gutter-2 .col-7, .container.from-bottom.gutter-2 .col-8, .container.from-bottom.gutter-2 .col-9, .container.from-bottom.gutter-2 .col-10, .container.from-bottom.gutter-2 .col-11 {
  margin-bottom: 2%;
}
.container.from-top.gutter-2 .col-1:last-child, .container.from-top.gutter-2 .col-2:last-child, .container.from-top.gutter-2 .col-3:last-child, .container.from-top.gutter-2 .col-4:last-child, .container.from-top.gutter-2 .col-5:last-child, .container.from-top.gutter-2 .col-6:last-child, .container.from-top.gutter-2 .col-7:last-child, .container.from-top.gutter-2 .col-8:last-child, .container.from-top.gutter-2 .col-9:last-child, .container.from-top.gutter-2 .col-10:last-child, .container.from-top.gutter-2 .col-11:last-child, .container.from-bottom.gutter-2 .col-1:last-child, .container.from-bottom.gutter-2 .col-2:last-child, .container.from-bottom.gutter-2 .col-3:last-child, .container.from-bottom.gutter-2 .col-4:last-child, .container.from-bottom.gutter-2 .col-5:last-child, .container.from-bottom.gutter-2 .col-6:last-child, .container.from-bottom.gutter-2 .col-7:last-child, .container.from-bottom.gutter-2 .col-8:last-child, .container.from-bottom.gutter-2 .col-9:last-child, .container.from-bottom.gutter-2 .col-10:last-child, .container.from-bottom.gutter-2 .col-11:last-child {
  margin-bottom: 0;
}
.container.from-top.gutter-3 .col-1, .container.from-top.gutter-3 .col-2, .container.from-top.gutter-3 .col-3, .container.from-top.gutter-3 .col-4, .container.from-top.gutter-3 .col-5, .container.from-top.gutter-3 .col-6, .container.from-top.gutter-3 .col-7, .container.from-top.gutter-3 .col-8, .container.from-top.gutter-3 .col-9, .container.from-top.gutter-3 .col-10, .container.from-top.gutter-3 .col-11, .container.from-bottom.gutter-3 .col-1, .container.from-bottom.gutter-3 .col-2, .container.from-bottom.gutter-3 .col-3, .container.from-bottom.gutter-3 .col-4, .container.from-bottom.gutter-3 .col-5, .container.from-bottom.gutter-3 .col-6, .container.from-bottom.gutter-3 .col-7, .container.from-bottom.gutter-3 .col-8, .container.from-bottom.gutter-3 .col-9, .container.from-bottom.gutter-3 .col-10, .container.from-bottom.gutter-3 .col-11 {
  margin-bottom: 3%;
}
.container.from-top.gutter-3 .col-1:last-child, .container.from-top.gutter-3 .col-2:last-child, .container.from-top.gutter-3 .col-3:last-child, .container.from-top.gutter-3 .col-4:last-child, .container.from-top.gutter-3 .col-5:last-child, .container.from-top.gutter-3 .col-6:last-child, .container.from-top.gutter-3 .col-7:last-child, .container.from-top.gutter-3 .col-8:last-child, .container.from-top.gutter-3 .col-9:last-child, .container.from-top.gutter-3 .col-10:last-child, .container.from-top.gutter-3 .col-11:last-child, .container.from-bottom.gutter-3 .col-1:last-child, .container.from-bottom.gutter-3 .col-2:last-child, .container.from-bottom.gutter-3 .col-3:last-child, .container.from-bottom.gutter-3 .col-4:last-child, .container.from-bottom.gutter-3 .col-5:last-child, .container.from-bottom.gutter-3 .col-6:last-child, .container.from-bottom.gutter-3 .col-7:last-child, .container.from-bottom.gutter-3 .col-8:last-child, .container.from-bottom.gutter-3 .col-9:last-child, .container.from-bottom.gutter-3 .col-10:last-child, .container.from-bottom.gutter-3 .col-11:last-child {
  margin-bottom: 0;
}
.container.from-top.gutter-4 .col-1, .container.from-top.gutter-4 .col-2, .container.from-top.gutter-4 .col-3, .container.from-top.gutter-4 .col-4, .container.from-top.gutter-4 .col-5, .container.from-top.gutter-4 .col-6, .container.from-top.gutter-4 .col-7, .container.from-top.gutter-4 .col-8, .container.from-top.gutter-4 .col-9, .container.from-top.gutter-4 .col-10, .container.from-top.gutter-4 .col-11, .container.from-bottom.gutter-4 .col-1, .container.from-bottom.gutter-4 .col-2, .container.from-bottom.gutter-4 .col-3, .container.from-bottom.gutter-4 .col-4, .container.from-bottom.gutter-4 .col-5, .container.from-bottom.gutter-4 .col-6, .container.from-bottom.gutter-4 .col-7, .container.from-bottom.gutter-4 .col-8, .container.from-bottom.gutter-4 .col-9, .container.from-bottom.gutter-4 .col-10, .container.from-bottom.gutter-4 .col-11 {
  margin-bottom: 4%;
}
.container.from-top.gutter-4 .col-1:last-child, .container.from-top.gutter-4 .col-2:last-child, .container.from-top.gutter-4 .col-3:last-child, .container.from-top.gutter-4 .col-4:last-child, .container.from-top.gutter-4 .col-5:last-child, .container.from-top.gutter-4 .col-6:last-child, .container.from-top.gutter-4 .col-7:last-child, .container.from-top.gutter-4 .col-8:last-child, .container.from-top.gutter-4 .col-9:last-child, .container.from-top.gutter-4 .col-10:last-child, .container.from-top.gutter-4 .col-11:last-child, .container.from-bottom.gutter-4 .col-1:last-child, .container.from-bottom.gutter-4 .col-2:last-child, .container.from-bottom.gutter-4 .col-3:last-child, .container.from-bottom.gutter-4 .col-4:last-child, .container.from-bottom.gutter-4 .col-5:last-child, .container.from-bottom.gutter-4 .col-6:last-child, .container.from-bottom.gutter-4 .col-7:last-child, .container.from-bottom.gutter-4 .col-8:last-child, .container.from-bottom.gutter-4 .col-9:last-child, .container.from-bottom.gutter-4 .col-10:last-child, .container.from-bottom.gutter-4 .col-11:last-child {
  margin-bottom: 0;
}
.container.from-top.gutter-5 .col-1, .container.from-top.gutter-5 .col-2, .container.from-top.gutter-5 .col-3, .container.from-top.gutter-5 .col-4, .container.from-top.gutter-5 .col-5, .container.from-top.gutter-5 .col-6, .container.from-top.gutter-5 .col-7, .container.from-top.gutter-5 .col-8, .container.from-top.gutter-5 .col-9, .container.from-top.gutter-5 .col-10, .container.from-top.gutter-5 .col-11, .container.from-bottom.gutter-5 .col-1, .container.from-bottom.gutter-5 .col-2, .container.from-bottom.gutter-5 .col-3, .container.from-bottom.gutter-5 .col-4, .container.from-bottom.gutter-5 .col-5, .container.from-bottom.gutter-5 .col-6, .container.from-bottom.gutter-5 .col-7, .container.from-bottom.gutter-5 .col-8, .container.from-bottom.gutter-5 .col-9, .container.from-bottom.gutter-5 .col-10, .container.from-bottom.gutter-5 .col-11 {
  margin-bottom: 5%;
}
.container.from-top.gutter-5 .col-1:last-child, .container.from-top.gutter-5 .col-2:last-child, .container.from-top.gutter-5 .col-3:last-child, .container.from-top.gutter-5 .col-4:last-child, .container.from-top.gutter-5 .col-5:last-child, .container.from-top.gutter-5 .col-6:last-child, .container.from-top.gutter-5 .col-7:last-child, .container.from-top.gutter-5 .col-8:last-child, .container.from-top.gutter-5 .col-9:last-child, .container.from-top.gutter-5 .col-10:last-child, .container.from-top.gutter-5 .col-11:last-child, .container.from-bottom.gutter-5 .col-1:last-child, .container.from-bottom.gutter-5 .col-2:last-child, .container.from-bottom.gutter-5 .col-3:last-child, .container.from-bottom.gutter-5 .col-4:last-child, .container.from-bottom.gutter-5 .col-5:last-child, .container.from-bottom.gutter-5 .col-6:last-child, .container.from-bottom.gutter-5 .col-7:last-child, .container.from-bottom.gutter-5 .col-8:last-child, .container.from-bottom.gutter-5 .col-9:last-child, .container.from-bottom.gutter-5 .col-10:last-child, .container.from-bottom.gutter-5 .col-11:last-child {
  margin-bottom: 0;
}
.container.from-top.gutter-6 .col-1, .container.from-top.gutter-6 .col-2, .container.from-top.gutter-6 .col-3, .container.from-top.gutter-6 .col-4, .container.from-top.gutter-6 .col-5, .container.from-top.gutter-6 .col-6, .container.from-top.gutter-6 .col-7, .container.from-top.gutter-6 .col-8, .container.from-top.gutter-6 .col-9, .container.from-top.gutter-6 .col-10, .container.from-top.gutter-6 .col-11, .container.from-bottom.gutter-6 .col-1, .container.from-bottom.gutter-6 .col-2, .container.from-bottom.gutter-6 .col-3, .container.from-bottom.gutter-6 .col-4, .container.from-bottom.gutter-6 .col-5, .container.from-bottom.gutter-6 .col-6, .container.from-bottom.gutter-6 .col-7, .container.from-bottom.gutter-6 .col-8, .container.from-bottom.gutter-6 .col-9, .container.from-bottom.gutter-6 .col-10, .container.from-bottom.gutter-6 .col-11 {
  margin-bottom: 6%;
}
.container.from-top.gutter-6 .col-1:last-child, .container.from-top.gutter-6 .col-2:last-child, .container.from-top.gutter-6 .col-3:last-child, .container.from-top.gutter-6 .col-4:last-child, .container.from-top.gutter-6 .col-5:last-child, .container.from-top.gutter-6 .col-6:last-child, .container.from-top.gutter-6 .col-7:last-child, .container.from-top.gutter-6 .col-8:last-child, .container.from-top.gutter-6 .col-9:last-child, .container.from-top.gutter-6 .col-10:last-child, .container.from-top.gutter-6 .col-11:last-child, .container.from-bottom.gutter-6 .col-1:last-child, .container.from-bottom.gutter-6 .col-2:last-child, .container.from-bottom.gutter-6 .col-3:last-child, .container.from-bottom.gutter-6 .col-4:last-child, .container.from-bottom.gutter-6 .col-5:last-child, .container.from-bottom.gutter-6 .col-6:last-child, .container.from-bottom.gutter-6 .col-7:last-child, .container.from-bottom.gutter-6 .col-8:last-child, .container.from-bottom.gutter-6 .col-9:last-child, .container.from-bottom.gutter-6 .col-10:last-child, .container.from-bottom.gutter-6 .col-11:last-child {
  margin-bottom: 0;
}
.container.from-top.gutter-7 .col-1, .container.from-top.gutter-7 .col-2, .container.from-top.gutter-7 .col-3, .container.from-top.gutter-7 .col-4, .container.from-top.gutter-7 .col-5, .container.from-top.gutter-7 .col-6, .container.from-top.gutter-7 .col-7, .container.from-top.gutter-7 .col-8, .container.from-top.gutter-7 .col-9, .container.from-top.gutter-7 .col-10, .container.from-top.gutter-7 .col-11, .container.from-bottom.gutter-7 .col-1, .container.from-bottom.gutter-7 .col-2, .container.from-bottom.gutter-7 .col-3, .container.from-bottom.gutter-7 .col-4, .container.from-bottom.gutter-7 .col-5, .container.from-bottom.gutter-7 .col-6, .container.from-bottom.gutter-7 .col-7, .container.from-bottom.gutter-7 .col-8, .container.from-bottom.gutter-7 .col-9, .container.from-bottom.gutter-7 .col-10, .container.from-bottom.gutter-7 .col-11 {
  margin-bottom: 7%;
}
.container.from-top.gutter-7 .col-1:last-child, .container.from-top.gutter-7 .col-2:last-child, .container.from-top.gutter-7 .col-3:last-child, .container.from-top.gutter-7 .col-4:last-child, .container.from-top.gutter-7 .col-5:last-child, .container.from-top.gutter-7 .col-6:last-child, .container.from-top.gutter-7 .col-7:last-child, .container.from-top.gutter-7 .col-8:last-child, .container.from-top.gutter-7 .col-9:last-child, .container.from-top.gutter-7 .col-10:last-child, .container.from-top.gutter-7 .col-11:last-child, .container.from-bottom.gutter-7 .col-1:last-child, .container.from-bottom.gutter-7 .col-2:last-child, .container.from-bottom.gutter-7 .col-3:last-child, .container.from-bottom.gutter-7 .col-4:last-child, .container.from-bottom.gutter-7 .col-5:last-child, .container.from-bottom.gutter-7 .col-6:last-child, .container.from-bottom.gutter-7 .col-7:last-child, .container.from-bottom.gutter-7 .col-8:last-child, .container.from-bottom.gutter-7 .col-9:last-child, .container.from-bottom.gutter-7 .col-10:last-child, .container.from-bottom.gutter-7 .col-11:last-child {
  margin-bottom: 0;
}
.container.from-top.gutter-8 .col-1, .container.from-top.gutter-8 .col-2, .container.from-top.gutter-8 .col-3, .container.from-top.gutter-8 .col-4, .container.from-top.gutter-8 .col-5, .container.from-top.gutter-8 .col-6, .container.from-top.gutter-8 .col-7, .container.from-top.gutter-8 .col-8, .container.from-top.gutter-8 .col-9, .container.from-top.gutter-8 .col-10, .container.from-top.gutter-8 .col-11, .container.from-bottom.gutter-8 .col-1, .container.from-bottom.gutter-8 .col-2, .container.from-bottom.gutter-8 .col-3, .container.from-bottom.gutter-8 .col-4, .container.from-bottom.gutter-8 .col-5, .container.from-bottom.gutter-8 .col-6, .container.from-bottom.gutter-8 .col-7, .container.from-bottom.gutter-8 .col-8, .container.from-bottom.gutter-8 .col-9, .container.from-bottom.gutter-8 .col-10, .container.from-bottom.gutter-8 .col-11 {
  margin-bottom: 8%;
}
.container.from-top.gutter-8 .col-1:last-child, .container.from-top.gutter-8 .col-2:last-child, .container.from-top.gutter-8 .col-3:last-child, .container.from-top.gutter-8 .col-4:last-child, .container.from-top.gutter-8 .col-5:last-child, .container.from-top.gutter-8 .col-6:last-child, .container.from-top.gutter-8 .col-7:last-child, .container.from-top.gutter-8 .col-8:last-child, .container.from-top.gutter-8 .col-9:last-child, .container.from-top.gutter-8 .col-10:last-child, .container.from-top.gutter-8 .col-11:last-child, .container.from-bottom.gutter-8 .col-1:last-child, .container.from-bottom.gutter-8 .col-2:last-child, .container.from-bottom.gutter-8 .col-3:last-child, .container.from-bottom.gutter-8 .col-4:last-child, .container.from-bottom.gutter-8 .col-5:last-child, .container.from-bottom.gutter-8 .col-6:last-child, .container.from-bottom.gutter-8 .col-7:last-child, .container.from-bottom.gutter-8 .col-8:last-child, .container.from-bottom.gutter-8 .col-9:last-child, .container.from-bottom.gutter-8 .col-10:last-child, .container.from-bottom.gutter-8 .col-11:last-child {
  margin-bottom: 0;
}
.container .inner {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.fieldWrapper .col-1 {
  width: 8.333%;
}
.fieldWrapper .col-2 {
  width: 16.667%;
}
.fieldWrapper .col-3 {
  width: 25%;
}
.fieldWrapper .col-4 {
  width: 33.333%;
}
.fieldWrapper .col-5 {
  width: 41.667%;
}
.fieldWrapper .col-6 {
  width: 50%;
}
.fieldWrapper .col-7 {
  width: 58.333%;
}
.fieldWrapper .col-8 {
  width: 66.667%;
}
.fieldWrapper .col-9 {
  width: 75%;
}
.fieldWrapper .col-10 {
  width: 83.333%;
}
.fieldWrapper .col-11 {
  width: 91.667%;
}
.fieldWrapper .col-12 {
  width: 100%;
}

.fieldGroup {
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  background: #FAF9FC;
}
.fieldGroup:first-child {
  margin-top: 0;
}
.fieldGroup:last-child {
  margin-bottom: 0;
}
.fieldGroup .field {
  position: relative;
  float: left;
  padding: 8px;
}
.fieldGroup .field .fieldInner {
  display: flex;
}
.fieldGroup .field .fieldInner:focus-within .tag {
  box-shadow: 0 5px 9px rgba(91, 139, 250, 0.25);
  transition: all 0.25s ease;
  border-color: #5608D2;
}
.fieldGroup .field .fieldInner .tag {
  flex-basis: 35px;
  background: #FAF9FC;
  text-align: center;
  position: relative;
  transition: all 0.25s ease;
  border-radius: 3px;
  border: solid 1px #EBEBE8;
}
.fieldGroup .field .fieldInner .tag:last-child {
  margin-left: -4px;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.fieldGroup .field .fieldInner .tag:first-child {
  margin-right: -4px;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fieldGroup .field .fieldInner .tag.selectable {
  cursor: pointer;
}
.fieldGroup .field .fieldInner .tag a {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}
.fieldGroup .field .fieldInner .tag img {
  width: 13px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: brightness(0);
  opacity: 0.4;
}
.fieldGroup .col-1 {
  width: 8.333%;
}
.fieldGroup .col-2 {
  width: 16.667%;
}
.fieldGroup .col-3 {
  width: 25%;
}
.fieldGroup .col-4 {
  width: 33.333%;
}
.fieldGroup .col-5 {
  width: 41.667%;
}
.fieldGroup .col-6 {
  width: 50%;
}
.fieldGroup .col-7 {
  width: 58.333%;
}
.fieldGroup .col-8 {
  width: 66.667%;
}
.fieldGroup .col-9 {
  width: 75%;
}
.fieldGroup .col-10 {
  width: 83.333%;
}
.fieldGroup .col-11 {
  width: 91.667%;
}
.fieldGroup .col-12 {
  width: 100%;
}

.isWhite {
  background: #FAF9FC;
}

.isBlack {
  background: #0D0120;
}

.isDkCharcoal {
  background: #3e4146;
}

.isLtCharcoal {
  background: #616367;
}

.isLightGray {
  background: #FAFCFC;
}

.isPrimary {
  background: #5608D2;
}

.isSecondary {
  background: #F57C61;
}

.isLight {
  background: #FAFCFC;
}

.isDark {
  background: #3e4146;
}
.isDark input, .isDark select, .isDark textarea {
  background: #43464b;
  border-color: #43464b;
  color: #FAF9FC;
}
.isDark input:focus, .isDark select:focus, .isDark textarea:focus {
  box-shadow: 0 5px 9px rgba(13, 1, 32, 0.25);
  border-color: #F57C61;
  background: #393c41;
}
.isDark input[type=range] {
  background: transparent;
  border-color: transparent;
}
.isDark input[type=range]::-webkit-slider-runnable-track {
  background: #43464b;
}
.isDark input[type=range]::-webkit-slider-thumb {
  background: #FAF9FC;
}
.isDark input[type=range]:focus {
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.isDark input[type=radio]:focus, .isDark input[type=checkbox]:focus {
  border-color: transparent;
}
.isDark .fieldWrapper .fieldGroup {
  background: #5c5e62;
}
.isDark .fieldWrapper .fieldGroup label {
  color: #FAF9FC;
}
.isDark .fieldWrapper .fieldGroup .field {
  position: relative;
  float: left;
  padding: 8px;
}
.isDark .fieldWrapper .fieldGroup .field .fieldInner:focus-within .tag {
  box-shadow: 0 5px 9px rgba(13, 1, 32, 0.25);
  background: #393c41;
  transition: all 0.25s ease;
  border-color: #F57C61;
}
.isDark .fieldWrapper .fieldGroup .field .fieldInner .tag {
  flex-basis: 35px;
  border-color: #43464b;
  background: #43464b;
}
.isDark .fieldWrapper .fieldGroup .field .fieldInner .tag img {
  filter: brightness(0) invert(100%);
  opacity: 0.8;
}
.isDark .fieldWrapper .fieldGroup .selectBlock::after {
  border-top: 5px solid #b0b0a4;
}
.isDark .fieldWrapper .fieldGroup .selectBlock:focus-within::after {
  border-top: 5px solid #F57C61;
}

.Nav {
  display: flex;
  position: relative;
  z-index: 100;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.Nav .menu {
  flex-grow: 1;
}
.Nav .top {
  padding: 30px 40px;
  background: #0D0120;
  display: inline-block;
  z-index: 1;
  position: relative;
}

.bgBlobs {
  transition: all 0.2s ease-in-out;
  position: absolute;
  width: calc(100vw - 30px);
  height: calc(100vh - 30px);
  top: 0px;
  left: 0px;
  z-index: 0;
  overflow: hidden;
}
.bgBlobs svg {
  filter: blur(300px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-30%) translateY(-50%);
  opacity: 0.6;
  z-index: 2;
}
.bgBlobs svg.whiteBlob {
  transform: translateX(-70%) translateY(-40%);
  z-index: 0;
}
.bgBlobs::before {
  content: "";
  background-image: url("../assets/noise.png");
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: 200px 200px;
  background-repeat: repeat;
  z-index: 1000;
  pointer-events: none;
}

.Generator {
  transition: all 0.2s ease-in-out;
  position: relative;
  padding: 6vw 8%;
}
.Generator .nolanSays {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
.Generator .nolanSays p {
  margin: 0;
  font-size: 2.3rem;
}
.Generator .nolan {
  width: 5rem;
  border-radius: 100px;
}
.Generator h2 {
  font-size: 5vw;
  line-height: 1.25;
  font-family: obviously, sans-serif;
  text-shadow: 0 9px 20px rgba(13, 1, 32, 0.15);
  transition: all 0.2s ease-in-out;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}
.Generator h2 span {
  position: relative;
  padding-bottom: 10px;
  padding: 0;
  border-bottom: solid 0px #FAF9FC;
  transition: all 0.2s ease-in-out;
}
.Generator h2 span svg {
  opacity: 0.3;
  transition: all 0.2s ease-in-out;
}
.Generator h2 span:hover {
  border-width: 2px;
  cursor: pointer;
}
.Generator h2 span:hover svg {
  opacity: 1;
}

.closeIt {
  display: none;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.closeIt:hover {
  opacity: 0.7;
}

.wrapper {
  position: relative;
}

.Sharing {
  width: 100vw;
  height: 100vh;
  margin-top: -15px;
  margin-left: -15px;
  background: #0D0120;
  padding-top: 5vh;
}
.Sharing .wrapper {
  position: relative;
  width: 960px;
  height: 540px;
  border: solid #0D0120 5px;
  background-color: #432277;
  padding: 0;
  margin: auto;
}
.Sharing .bgBlobs {
  width: 100%;
  height: 100%;
}
.Sharing .closeIt {
  display: block;
}
.Sharing .Generator {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  pointer-events: none;
}
.Sharing .Generator h2 {
  font-size: 5rem;
}
.Sharing .Generator h2 span svg {
  display: none;
}
.Sharing .Nav {
  position: absolute;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-end;
}
.Sharing .Share_container {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  margin-top: 20px;
}

.joinNow {
  position: absolute;
  bottom: 15px;
  right: 15px;
  padding: 17px;
  text-align: center;
  background: rgba(13, 1, 32, 0.55);
  transition: all 0.2s ease-in-out;
}
.joinNow img {
  margin-bottom: -5px;
  opacity: 0.4;
  transition: all 0.2s ease-in-out;
}
.joinNow:hover {
  background: rgba(13, 1, 32, 0.15);
  cursor: pointer;
}
.joinNow:hover img {
  opacity: 1;
}
.joinNow p {
  margin: 0;
}

@media only screen and (max-width: 1024px) {
  .Generator {
    padding: 16vh 8%;
  }
  .Generator h2 {
    font-size: 6vw;
  }
}
@media only screen and (max-width: 900px) {
  .Generator {
    padding: 13vh 6%;
  }
  .Generator h2 {
    font-size: 7.5vw;
  }
}
@media only screen and (max-width: 768px) {
  body {
    border: 0;
  }

  .bgBlobs {
    width: 100vw;
    height: 100vh;
  }
  .bgBlobs svg {
    filter: blur(35px);
    opacity: 0.4;
    width: 200%;
    transform: translate(-30%, -65%);
  }
  .bgBlobs svg.whiteBlob {
    transform: translateX(-53%) translateY(-45%);
  }

  .Generator {
    padding: 8vh 6%;
  }
  .Generator h2 {
    font-size: 9.5vw;
  }
  .Generator h2 svg {
    width: 24px;
    margin-bottom: -8px;
  }

  .randomize {
    position: fixed;
    bottom: 12px;
    left: 14px;
    text-wrap: nowrap;
    margin: 0;
  }

  .Nav .top {
    padding: 24px 32px;
  }
  .Nav .top img {
    width: 40px;
  }

  .joinNow {
    padding: 15px 15px 21px;
    width: calc(100% - 240px);
    background: rgba(13, 1, 32, 0.15);
    bottom: 0;
    right: 0;
  }
  .joinNow img {
    display: none;
  }
}
